html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a,
a:hover {
  text-decoration: none;
}

.smcx-embed>.smcx-iframe-container,
.smcx-embed {
  max-width: 100% !important;
}

.MuiChip-deleteIcon {
  color: rgb(63 59 59) !important;
}
.MuiFormLabel-root.Mui-focused {
  color: #403C3C !important;
}

.calendly-overlay .calendly-popup .calendly-popup-content {
  background: #fff !important;
  border-radius: 6px;
}

.calendly-overlay .calendly-popup-close{
  display: none !important;
}
.tootiptitledesc{
  display: none;
}