
/* Hubspot form styles */

.hs-button{
    font-family:"Roboto",Helvetica,Arial,sans-serif;
    margin:0;
    cursor:pointer;
    display:inline-block;
    font-weight:700;
    line-height:12px;
    position:relative;
    text-align:center;
    transition:all .15s linear;
    background-color:#D62311!important;
    border-color:#D62311!important;
    color:#fff;
    border-radius:100px;
    border-style:solid;
    border-width:1px;
    font-size:16px;
    padding:12px 24px
  }
  .hs-button:hover, .hs-button:focus{
    background-color:#95180b!important;
    border-color:#95180b!important;
    outline: none!important;
  }
  .hs-button > .MuiTouchRipple-root > span {
    opacity: 0.3!important;
  } 
  .hs-button:active{
    background-color:#95180b!important;
    border-color:#95180b!important
  }
  label{
    font-size:14px;
    width:130px;
    color:#403C3C;
    display:block;
    float:none;
    width:auto;
    font-weight:normal;
    line-height:20px;
    padding-top:0;
    margin-bottom:4px;
    font-family:"Helvetica Neue",Helvetica,Arial,sans-serif
  }
  .hs-video-form label{
    color:#fff !important
  }
  .hs-video-form .hs-back-button{
    background-color:#fff;
    width:20%;
    height:38px;
    margin-right:10px;
    border:1px solid;
    -webkit-border-radius:3px;
    -moz-border-radius:3px;
    -ms-border-radius:3px;
    border-radius:3px
  }
  .hs-video-form .hs-button{
    width:70%
  }
  .hs-video-form .hs-button span{
    font-size:15px
  }
  .hs-video-form .hs-richtext{
    color:#fff !important
  }
  .hs-video-form .legal-consent-container{
    max-height:180px !important;
    overflow-y:scroll !important
  }
  .hs-video-form .legal-consent-container:after{
    content:"";
    display:block;
    height:100px;
    width:100%
  }
  .hs-video-form .legal-consent-wrapper{
    position:relative
  }
  .hs-video-form .legal-consent-overlay{
    position:absolute;
    pointer-events:none;
    left:0;
    bottom:0;
    height:100px;
    width:100%;
    background:linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #292929 100%)
  }
  .hs-error-msgs label{
    color:#e42a2d;
    padding-top:4px
  }
  .hs-error-msgs.inputs-list{
    margin:0;
    padding-top:4px
  }
  .hs-input{
    display:inline-block;
    width:100%;
    height:40px;
    padding:9px 10px;
    font-family:"Roboto",Helvetica,Arial,sans-serif;
    font-size:16px;
    font-weight:normal;
    line-height:22px;
    color:#403C3C;
    border:1px solid rgba(0, 0, 0, 0.23);
    border-radius: 4px;
    background-color: #fff!important;
    box-sizing:border-box;
    -webkit-border-radius:3px;
    -moz-border-radius:3px;
    -ms-border-radius:3px;
  }
  .hs-fieldtype-textarea{
    width:100%;
  }
  .hs-fieldtype-textarea{
    width:100%;
  }
  .hs-input:not([type=file]){
    background-color:#f5f8fa
  }
  .hs-input:disabled {
    opacity: 0.7;
}

  .hs-input[type=checkbox],.hs-input[type=radio]{
    cursor:pointer;
    width:16px;
    height:16px;
    padding:0;
    margin:3px 5px 3px 0px;
    line-height:normal;
    border:none
  }
  .hs-input[type=checkbox]:checked{
    accent-color: #000000;
  }
  .hs-input[type=file]{
    padding:initial;
    border:initial;
    line-height:initial;
    box-shadow:none
  }
  .hs-input[type=file]::file-selector-button{
    border-radius: 100px;
    background-color: #fff;
    border:1px solid #ccc;
    padding: 5px 14px 5px 12px;
    font-size: 14px;
  }
  select.is-placeholder{
    color:#403C3C
  }
  select.is-placeholder:focus{
    color:#403C3C
  }
  .hs-input:-moz-placeholder{
    color:#403C3C
  }
  .hs-input::-webkit-input-placeholder{
    color:#403C3C
  }
  .hs-input option:disabled{
    color:#403C3C
  }
  .hs-input input,.hs-input textarea{
    transition:border .2s linear
  }
  .hs-input:focus{
    outline:none;
    border-color:#000000
  }
  textarea.hs-input{
    height:auto;
    max-width: 550px;
    min-height: 150px;
  }
  select[multiple].hs-input{
    height:inherit
  }
  input.hs-input.error,div.field.error input,div.field.error textarea,div.field.error .chzn-choices,textarea.hs-input.error,select.hs-input.error{
    border-color:#c87872
  }
  input.hs-input.error:focus,div.field.error input:focus,div.field.error textarea:focus,div.field.error .chzn-choices:focus,textarea.hs-input.error:focus,select.hs-input.error:focus{
    border-color:#b9554d
  }
  .actions{
    margin-top:18px;
    margin-bottom:18px;
    padding:17px 0px
  }
  .inputs-list{
    margin:0 0 5px;
    width:100%;
    padding-left:5px
  }
  .inputs-list>li{
    display:block;
    padding:0;
    width:100%;
    padding-top:0
  }
  .inputs-list label{
    float:none;
    width:auto;
    padding:0;
    line-height:18px;
    white-space:normal;
    font-weight:normal
  }
  .inputs-list:first-child{
    padding-top:6px
  }
  .inputs-list>li+li{
    padding-top:2px
  }
  ul.no-list{
    list-style:none
  }
  .field{
    margin-bottom:18px
  }
  .hs-field-desc{
    color:#7c98b6;
    margin:0px 0px 6px;
    font-size:11px;
    font-family:"Roboto",Helvetica,Arial,sans-serif
  }
  .hs-form-required{
    color:red
  }
  .hs-richtext{
    margin-bottom:3px;
    font-family:"Roboto",Helvetica,Arial,sans-serif;
    line-height:24px;
    font-size:16px;
    color:#403C3C
  }
  .hs-richtext hr{
    margin-left:0;
    width:91%
  }
  .hs-custom-style .hs-dependent-field>div input.hs-input:not([type=checkbox]):not([type=radio]){
    width:90%
  }
  form.hs-form-rtl .field{
    flex:0 1 100%
  }
  form.hs-form-rtl .hs-input[type=checkbox],form.hs-form-rtl .hs-input[type=radio]{
    margin:3px 5px 3px 5px
  }
  form.hs-form-rtl fieldset{
    display:flex
  }
  form.hs-form-rtl fieldset[class^=form-columns-] .input{
    margin-right:0px
  }
  form.hs-form-rtl ul{
    padding:0px
  }
  form.hs-form-rtl .legal-consent-container .hs-form-booleancheckbox-display input{
    width:auto;
    float:right
  }
  form.hs-form-rtl .legal-consent-container .hs-form-booleancheckbox-display>span{
    margin-left:0px
  }
  form.hs-form-rtl .hs-dependent-field{
    display:flex;
    flex:0 1 100%;
    flex-wrap:wrap
  }
  .email-correction,.email-validation{
    padding-top:3px;
    font-size:12px;
    font-family:"Roboto",Helvetica,Arial,sans-serif
  }
  .email-correction a,.email-validation a{
    cursor:pointer
  }
  @media(max-width: 400px),(min-device-width: 320px)and (max-device-width: 480px){
    .email-correction form .form-columns-2 .hs-form-field,.email-correction form .form-columns-3 .hs-form-field,.email-validation form .form-columns-2 .hs-form-field,.email-validation form .form-columns-3 .hs-form-field{
        float:none;
        width:100%
    }
    .email-correction form .form-columns-2 .hs-form-field .hs-input,.email-correction form .form-columns-3 .hs-form-field .hs-input,.email-validation form .form-columns-2 .hs-form-field .hs-input,.email-validation form .form-columns-3 .hs-form-field .hs-input{
        width:90%
    }
    .email-correction form .form-columns-2 .hs-form-field input[type=checkbox],.email-correction form .form-columns-2 .hs-form-field input[type=radio],.email-correction form .form-columns-3 .hs-form-field input[type=checkbox],.email-correction form .form-columns-3 .hs-form-field input[type=radio],.email-validation form .form-columns-2 .hs-form-field input[type=checkbox],.email-validation form .form-columns-2 .hs-form-field input[type=radio],.email-validation form .form-columns-3 .hs-form-field input[type=checkbox],.email-validation form .form-columns-3 .hs-form-field input[type=radio]{
        width:24px
    }
  }
  .hs-button,.hs-form-field input[type=text],.hs-form-field input[type=email],.hs-form-field input[type=phone],.hs-form-field input[type=number],.hs-form-field input[type=tel],.hs-form-field input[type=date],.hs-form-field textarea{
    -webkit-appearance:none;
    -moz-appearance:none
  }
  .hs-default-font-element,.hs-main-font-element{
    font-family:"Roboto",Helvetica,Arial,sans-serif;
    line-height:normal
  }
  .hs-form {
     width: 100% 
  }
   .hs-submit > .actions {
     text-align: left;
  }
   .hs-button,  .hs-button:hover,  .hs-button:hover:not(.inactive),  .hs-button:focus,  .hs-button:active,  .hs-button:active:not(.inactive):not(.link) {
     background: #0077C8;
     border-color: #0077C8;
     color: #ffffff;
     font-size: 16px;
     line-height: 12px;
     font-family: Roboto;
  }
   legend.hs-field-desc {
     font-family: Roboto;
     color: #7C98B6;
     font-size: 11px;
  }
   .hs-form-field label:not(.hs-error-msg) {
     font-family: Roboto;
     font-size: 16px;
  }
   .hs-form-field label:not(.hs-error-msg) {
     color: #403C3C;
  }
   .legal-consent-container .hs-richtext,  .legal-consent-container label:not(.hs-error-msg) {
     font-family: Roboto;
     color: #33475b;
     font-size: 14px;
  }
   .hs-main-font-element, .submitted-message.hs-main-font-element{
     font-family: Roboto;
  }
  .submitted-message {
     font-size: 16px;
     color: rgba(0, 0, 0, 0.87);
  }
  a:link,  a:active {
    color: #0b57d0 
  }
  a:hover {
    color: '#0b57d0';
    text-decoration: 'underline';
  }
  a:visited {
     color: #795cb2 ;
  }
  .fn-date-picker.pika-single{
    z-index:9999;
    display:block;
    position:relative;
    color:#333;
    background:#fff;
    border:1px solid #ccc;
    border-bottom-color:#bbb;
    font-family:"Roboto",Helvetica,Arial,sans-serif;
    *zoom:1
  }
  .fn-date-picker.pika-single.is-hidden{
    display:none
  }
  .fn-date-picker.pika-single.is-bound{
    position:absolute;
    box-shadow:0 5px 15px -5px rgba(0,0,0,.5)
  }
  .fn-date-picker.pika-single:after,.fn-date-picker.pika-single:before{
    content:" ";
    display:table
  }
  .fn-date-picker.pika-single:after{
    clear:both
  }
  .fn-date-picker .pika-lendar{
    float:left;
    width:240px;
    margin:8px
  }
  .fn-date-picker .pika-title{
    position:relative;
    text-align:center
  }
  .fn-date-picker .pika-title select{
    cursor:pointer;
    position:absolute;
    z-index:9998;
    margin:0;
    left:0;
    top:5px;
    filter:alpha(opacity=0);
    opacity:0
  }
  .fn-date-picker .pika-label{
    display:inline-block;
    *display:inline;
    position:relative;
    z-index:9999;
    overflow:hidden;
    margin:0;
    padding:5px 3px;
    font-size:14px;
    line-height:20px;
    font-weight:700;
    background-color:#fff
  }
  .fn-date-picker .pika-next,.fn-date-picker .pika-prev{
    display:block;
    cursor:pointer;
    position:relative;
    outline:none;
    border:0;
    padding:0;
    width:20px;
    height:30px;
    text-indent:20px;
    white-space:nowrap;
    overflow:hidden;
    background-color:transparent;
  }
  .hubspotcta-dialog .hs-form-field label:not(.hs-error-msg) {
    color: #403C3C !important;
    font-size: 14px !important;
    font-family: "Roboto","Helvetica","Arial",sans-serif;

  }

  .hubspotcta-dialog .hs-form-field > label:not(.hs-error-msg) {
    font-weight: 500;
  }

  .hs-main-font-element p span {
    font-size: 15px !important;
    color: #403C3C !important;
    font-weight: 400;
    line-height: 1.6;
    letter-spacing: 0.0075em;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
  }
  .hs-main-font-element h1{
    margin-top: 0;
  }
  .hs-main-font-element h1 span {
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 500;
    font-size: 1.25rem !important;
    line-height: 1.6;
    letter-spacing: 0.0075em;
    color: #403C3C !important;
  }
  .hubspotcta-dialog .submitted-message p {
    margin-top: 0 !important;
  }
  #hubspotcta-dialog .MuiDialog-paper {
    max-width: fit-content;
    min-width: 500px;
}

#hubspotForm input::placeholder, #hubspotForm textarea::placeholder  {
  opacity: 0.5;
}
  /* End of Hubspot styles */